import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "x0"
    }}>{`x0`}</h1>
    <p><a parentName="p" {...{
        "href": "https://compositor.io/x0"
      }}>{`x0`}</a>{` is a zero-config tool with built in support for MDX.  You will need
to install the library and set up the npm script.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install --save-dev @compositor/x0
`}</code></pre>
    <p>{`Then, in your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` add the following to the `}<inlineCode parentName="p">{`scripts`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "start": "x0 docs",
  "build": "x0 build docs"
}
`}</code></pre>
    <h2 {...{
      "id": "customizing-the-layout"
    }}>{`Customizing the layout`}</h2>
    <p><a parentName="p" {...{
        "href": "https://compositor.io/x0"
      }}>{`x0`}</a>{` supports MDX files with either `}<inlineCode parentName="p">{`.md`}</inlineCode>{` or `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` file extensions out of
the box.  For components requiring providers you will need to use customize
`}<inlineCode parentName="p">{`_app.js`}</inlineCode>{`.  Here’s an example using `}<a parentName="p" {...{
        "href": "https://rebassjs.com"
      }}>{`Rebass`}</a>{` components:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import * as Rebass from 'rebass'
import createScope from '@rebass/markdown'
import { ScopeProvider } from '@compositor/x0/components'

export default ({ route, routes, ...props }) => (
  <ScopeProvider scope={{ ...Rebass, ...createScope() }}>
    <Rebass.Provider>
      <Rebass.Container {...props} />
    </Rebass.Provider>
  </ScopeProvider>
)
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      